(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
'use strict';

var $  = require('jquery');

$(document).ready(function(){
    $('a').click(function(){
      var location = $(this).attr('href');
      $('html, body').animate({
          scrollTop: $( $.attr(this, 'href') ).offset().top
      }, 500, function(){
        window.location.href = location;
      });
      return false;
  });
});
}).call(this,require("oMfpAn"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_351b24b2.js","/")